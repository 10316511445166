/* Responsive Design */
@media (max-width: 768px) {
  .feature-grid {
    display: flex;
    flex-direction: column;
  }

  .header .nav {
    display: flex;
    background-color: white;
    align-items: center;
    align-content: space-between;
    flex-direction: row;
    position: fixed;
    width: -webkit-fill-available;
    padding: 10px;
    z-index: 1000;
    justify-content: space-between;
    top: 0px;
    left: 0px;
    border-radius: 0px;
  }

  .nav img {
    max-width: 20vh;
  }

  .navbar-hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 30px;
  }

  .navbar-hamburger .line {
    height: 3px;
    background-color: #00a3bb;
    width: 100%;
  }

  .side-panel {
    position: fixed;
    top: 0;
    right: -250px; /* Initially hidden */
    height: 100%;
    width: 250px;
    background-color: #f8f9fa; /* Adjust color */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
  }

  .side-panel.open {
    right: 0; /* Slide in */
  }

  .nav-links {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .banner {
    flex-direction: column;
  }

  .banner-text {
    text-align: center;
  }

  .banner-text h1 {
    text-align: center;
    font-size: 32px;
  }

  .banner-text p {
    text-align: center;
  }

  .find-hero-image img {
    display: none;
  }

  .secondpage-features-section {
    flex-direction: column;
    padding: 0 25px;
  }

  .secondpage-features {
    display: flex;
    flex-direction: column;
  }

  .secondpage-features-text {
    text-align: center;
  }

  .secondpage-feature-item span {
    text-align: left;
  }

  .platform-features h2 {
    text-align: center;
  }

  .get-started-btn {
    padding: 10px 50px;
    font-weight: 100;
  }

  .feature-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .platform-features {
    padding: 50px 15px;
  }
}
