/* General styles */
body {
  font-family: "Montserrat", Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f8f9fa;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='48' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFF' stroke='%2300A1AA' stroke-width='1.25' d='M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z'/%3E%3C/svg%3E"),
    auto;
}

h1 {
  font-size: 42px;
}

/* Header Section */
.header {
  text-align: center;
  background-color: white;
  padding: 25px;
}

.header .nav {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.banner-flex {
  display: flex;
}

.header .nav button {
  border: none;
  padding: 10px 20px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  border-radius: 20px;
  transition: 0.3s;
  margin: 5px;
  background-color: white;
  font-family: "montserrat";
  font-weight: bold;
}

.header .nav button:hover {
  background-color: #50cada;
  color: white;
}

.button-button-border {
  border: 1px solid #50cada !important;
  color: #00a3bb;
}

.button-button-login {
  background-color: #00a3bb !important;
  color: white;
}

.button-button-login:hover {
  background-color: #50cada !important;
  color: white;
  border: none;
}

.hero {
  margin: 40px 0;
  display: flex;
  padding: 15px;
  justify-content: center;
  flex-direction: row;
  align-items: center; /* Center items */
  width: 95%;
}

.hero-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  align-items: flex-start;
  text-align: left;
}

.hero h1 {
  font-size: 42px;
  font-weight: bold;
}

.hero p {
  font-size: 26px;
  margin: 15px 0;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.hero-buttons button {
  border-radius: 20px;
  border: none;
  padding: 10px 20px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  font-family: "montserrat";
  font-weight: 500;
  transition: 0.3s;
}

.practitioner-user-btn {
  color: white;
  background-color: #00a3bb;
}

.practitioner-user-btn:hover {
  background-color: #50cada;
}

.patient-user-btn {
  background-color: #d9f2f6;
  color: #00a3bb;
}

.patient-user-btn:hover {
  background-color: #50cada;
  color: white;
}

.hero-image {
  width: 50%;
  align-content: center;
}

/* Features Section */
.features {
  padding: 40px;
  text-align: center;
}

.features h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 30px;
}

.feature-cards {
  display: flex;
  gap: 20px;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  align-content: center;
  width: 34vh;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.feature-card h3 {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px;
}

.feature-card p {
  font-size: 0.95em;
  color: #666;
}

/* Specialties Section */
.specialties {
  text-align: center;
  margin: 40px 5px;
}

.specialty-list button {
  background-color: white;
  border: 1px solid #e0e0e0;
  margin: 10px 25px;
  padding: 20px 40px;
  border-radius: 15px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  font-size: 18px;
  font-weight: 600;
  font-family: "montserrat";
}

.specialty-list img {
  margin: 0 0 -4px 6px;
}

.specialty-list button:hover {
  background-color: #e0e0e0;
}

/* Practitioners & Individuals Section */
.practitioners-individuals {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0;
}

.practitioners {
  padding: 40px;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: white;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  margin: 0 40px 0 40px;
}

.individuals {
  padding: 40px;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 40px 0 40px;
}

.roles-sections {
  color: #00a3bb;
  font-size: 25px;
}

.for-practitioner-text {
  max-width: 100vh;
}

.for-individuals-text {
  max-width: 100vh;
}

.for-practitioner-text h2 {
  font-size: 30px;
}

.practitioners button,
.individuals button {
  background-color: #00a3bb;
  color: #fff;
  padding: 10px 20px;
  border: none;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  border-radius: 20px;
}

.for-practitioners-image img,
.for-individuals-image img {
  width: 300px;
}

/* Testimonials Section */
.testimonials {
  text-align: center;
  padding: 40px 0;
}

.testimonial-cards {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.testimonial-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.testimonial-card img {
  width: 75px;
  border-radius: 75px;
}

/* FAQ Section */
.faq {
  margin-top: 20px;
  padding: 0 150px;
}

.faq h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.faq-list {
  list-style-type: none;
  padding: 0;
}

.faq-item {
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
}

.faq-item:hover {
  transition: 0.3s;
  background-color: #50cada3d;
}

.faq-item:focus-visible {
  background-color: #00a3bb;
  color: white;
}

.faq-question {
  font-size: 18px;
  font-weight: bold;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-answer {
  margin-top: 10px;
  font-size: 16px;
}

.faq-icon {
  font-size: 20px;
}

.faq-question.active {
  color: #00a3bb;
}

.faq-item.active .faq-answer {
  display: block;
}

/* Download Section */
.download {
  padding: 40px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.download-img-area img {
  width: 80vh;
}

.download-text-area {
  max-width: 100vh;
}

.download-links {
  scale: 0.6;
  -webkit-transform-origin-x: left;
}

.download button {
  background-color: #00a3bb;
  color: #fff;
  padding: 10px 20px;
  border: none;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  border-radius: 20px;
}

/* Contact Section */
.contact {
  background-color: white;
  padding: 40px;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  flex-direction: column; /* Stack items on smaller screens */
  margin: 25px;
}

.contact-section {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.contact-details {
  flex: 1;
}

.contact-details h2 {
  font-size: 1.8rem;
  font-weight: bold;
}

.contact-info p {
  margin: 5px 0;
  font-size: 1.1rem;
}

.contact-info i {
  margin-right: 8px;
  color: #00a3bb;
}

.contact-form-section {
  flex: 1;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-wrapper {
  position: static;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 15px;
  max-width: 400px;
}

.input-wrapper input {
  padding: 10px;
  border: none;
  margin: 0 0 0 20px;
  border-radius: 15px;
  width: -webkit-fill-available;
}

.input-wrapper input:focus-visible {
  outline: none; /* Optional: Removes outline as well */
}

.input-wrapper svg {
  display: none;
  position: absolute;
  left: 10px; /* Adjust the position of the icon */
  top: 50%;
  transform: translateY(-50%); /* Center the icon vertically */
  color: #00a3bb;
}

.submit-button {
  width: fit-content;
  background-color: #00a3bb;
  color: white;
  border: none;
  padding: 10px 60px;
  border-radius: 20px;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  transition: 0.3s;
}

.submit-button:hover {
  background-color: #50cada;
}

.footer-bottom {
  background-color: black;
  text-align: center;
  color: white;
  padding: 5px;
  border-radius: 10px;
  margin-top: 20px;
}

#recaptcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.homepage-video {
  justify-self: center;
}

.embed-responsive-item {
  border: 5px solid white;
  border-radius: 25px;
  width: 100vh;
  height: 56.3vh;
}

.mute-btn img:hover {
  transform: scale(1.3);
  transition: 0.3s;
}

/** LAPTOP SCREEN **/
.macbook {
  padding: 4% 6%;
  margin: 0 auto;
  max-width: 800px;
}

.screen {
  background: #000;
  border-radius: 3% 3% 0.5% 0.5% / 5%;
  margin: 0 auto;
  /* for aspect ratio */
  position: relative;
  width: 80%;
}

.screen:before {
  border: 2px solid #cacacc;
  border-radius: 3% 3% 0.5% 0.5% / 5%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.8) inset,
    0 0 1px 2px rgba(255, 255, 255, 0.3) inset;
  content: "";
  display: block;
  /* for aspect ratio ~67:100 */
  padding-top: 60%;
}

.screen:after {
  content: "";
  border-top: 2px solid rgba(255, 255, 255, 0.15);
  position: absolute;
  bottom: 0.75%;
  left: 0.5%;
  padding-top: 1%;
  width: 99%;
}

.viewport {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 4.3% 3.2%;
  background: #333;
}

.base {
  /* for aspect ratio */
  position: relative;
  width: 100%;
}

.base:before {
  content: "";
  display: block;
  /* for aspect ratio ~33:1000*/
  padding-top: 3.3%;
  /* stylin */
  background: linear-gradient(
    #eaeced,
    #edeef0 55%,
    #fff 55%,
    #8a8b8f 56%,
    #999ba0 61%,
    #4b4b4f 84%,
    #262627 89%,
    rgba(0, 0, 0, 0.01) 98%
  );
  border-radius: 0 0 10% 10%/ 0 0 50% 50%;
}

.base::after {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5),
    rgba(255, 255, 255, 0.8) 0.5%,
    rgba(0, 0, 0, 0.4) 3.3%,
    transparent 15%,
    rgba(255, 255, 255, 0.8) 50%,
    transparent 85%,
    rgba(0, 0, 0, 0.4) 96.7%,
    rgba(255, 255, 255, 0.8) 99.5%,
    rgba(0, 0, 0, 0.5) 100%
  );
  content: "";
  height: 53%;
  position: absolute;
  top: 0;
  width: 50%;
}

.notch {
  background: #ddd;
  border-radius: 0 0 7% 7% / 0 0 95% 95%;
  box-shadow: -5px -1px 3px rgba(0, 0, 0, 0.2) inset,
    5px -1px 3px rgba(0, 0, 0, 0.2) inset;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3.5%;
  z-index: 2;
  /* for aspect ratio */
  position: relative;
  width: 14%;
}

.notch:before {
  content: "";
  display: block;
  /* for aspect ratio ~1:10 */
  padding-top: 10%;
}

/* Screenshot Hover Scroll Additions
  -------------------------------------------------------------- */

.macbook .viewport {
  transition: background-position 3s ease;
  background-position: 0 0;
  background-size: 100% auto;
  overflow: hidden;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
}

.macbook:hover .viewport {
  background-position: 0 100%;
}

.macbook .viewport:after {
  background: radial-gradient(
    circle at right bottom,
    transparent 75%,
    rgba(255, 255, 255, 0.05) 75%,
    transparent
  );
  bottom: 0;
  content: "";
  left: 0;
  /* position: absolute; */
  right: 0;
  top: 0;
}

.laptop__shadow {
  position: absolute;
  right: -10%;
  bottom: -2.5rem;
  left: -10%;
  z-index: 0;
  height: 2rem;
  background: radial-gradient(ellipse closest-side, #000, transparent);
  opacity: 0.5;
}
