/* Header */
.header {
  padding: 20px;
}

/* .header .nav {
  padding: 0 15px 0 15px;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
} */

.find-body {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.find-body header {
  width: -webkit-fill-available;
}

.find-body section {
  width: 90%;
}

.find-banner {
  display: flex;
  background-color: white;
}

.find-hero-text {
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
}

.find-practitioners-banner-h1 {
  color: black;
  font-size: 35px;
}

.practitioner-list {
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
  width: 87%;
}

.practitioner-list li {
  background-color: white;
  font-weight: 100;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #50CADA;
}

.registered-btn {
  padding: 10px;
  background-color: #50cada;
  color: white;
  border: none;
  border-radius: 4px;
}

.not-registered-btn {
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  padding: 10px;
  color: black;
}

.find-hero-image {
  padding: 25px;
}

.find-hero-image img {
  width: 60vh;
}

.search-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #00a3bb;
  padding: 15px;
  border-radius: 5px;
  min-width: fit-content;
  justify-content: center;
}

.search-item {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 200px; /* Adjust for responsiveness */
}

.search-item label {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
  color: #fff; /* Adjust as needed */
}

.search-item input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.header .nav button {
  border: none;
  padding: 10px 20px;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  border-radius: 20px;
  transition: 0.3s;
  margin: 5px;
  background-color: white;
  font-family: "montserrat";
  font-weight: bold;
}

.header .nav button:hover {
  background-color: #50cada;
  color: white;
  border: none;
}

.button-button-border {
  border: 1px solid #50cada !important;
  color: #00a3bb;
}

.button-button-login {
  background-color: #00a3bb !important;
  color: white;
}
.button-button-login:hover {
  background-color: #50cada !important;
  color: white;
  border: none;
}

/* Banner Section */
.banner {
  background-color: white;
  display: flex;
  padding: 45px 45px 20px 45px;
  justify-content: space-between;
  align-items: center;
}

.banner-text {
  max-width: 500px;
}

.banner-text h1 {
  font-size: 42px;
  font-weight: bold;
  text-align: left;
}

.banner-text p {
  margin-bottom: 20px;
  font-size: 16px;
}

.get-started-btn {
  background-color: #00a3bb;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 60px;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  transition: 0.3s;
}

.get-started-btn:hover {
  background-color: #50cada;
}

/* Features Section */
.secondpage-features-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 200px;
}

.secondpage-features-img {
  flex: 1;
  display: flex;
  justify-content: center;
}

.secondpage-features-img img {
  max-width: 100%;
  height: auto;
  border-radius: 20px; /* Optional for rounded corners */
}

.secondpage-features-text {
  flex: 1;
  text-align: left;
}

.secondpage-features-text h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.secondpage-features-text p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #555; /* Adjust color as needed */
}

.secondpage-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.secondpage-feature-item {
  display: flex;
  align-items: center;
  background-color: #f8faff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 1rem;
  color: #333;
}

.secondpage-feature-item img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.secondpage-feature-item span {
  display: inline-block;
}

/* Platform Features */
.platform-features {
  padding: 50px 90px;
  background-color: #f9fafa;
  justify-items: center;
}

.platform-features h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 700;
}

.get-started-btn {
  background-color: #00a3bb;
  color: #fff;
  padding: 12px 60px;
  border: none;
  border-radius: 25px;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 40px;
  transition: background-color 0.3s ease;
}

.get-started-btn:hover {
  background-color: #50cada;
}

.feature-grid {
  display: flex;
}

.feature-card {
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  text-align: center;
  width: 34vh;
}

.feature-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 600;
}

.feature-card-p {
  font-size: 1rem;
  color: black;
  font-weight: 500;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Pricing Plans */
.pricing-plans {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
  justify-items: center;
}

.pricing-plans h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.pricing-plans p {
  color: #666;
  margin-bottom: 30px;
}

.plans {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.plan-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.plan-card h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.plan-card .price {
  font-size: 32px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

.plan-card .premium-price {
  font-size: 32px;
  color: white;
  margin-bottom: 15px;
  font-weight: 600;
}

.plan-card ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.plan-card li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.plan-card li::before {
  content: "✔";
  color: #00a3bb;
  margin-right: 10px;
}

.premium-plan li::before {
  content: "✔";
  color: white;
  margin-right: 10px;
}

.pricing-get-started-btn {
  background-color: #00a3bb;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 20px;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  font-size: 16px;
  width: -webkit-fill-available;
}

.pricing-get-started-btn:hover {
  background-color: #50cada;
}

.basic-plan .pricing-get-started-btn {
  background-color: #00a3bb;
}

.standard-plan .pricing-get-started-btn {
  background-color: #00a3bb;
}

.premium-plan {
  background-color: #00a3bb;
  color: #fff;
}

.premium-plan .pricing-get-started-btn {
  background-color: white;
  color: #00a3bb;
}

.premium-plan h3 {
  color: white;
}

.premium-plan p {
  color: white;
}

.toggle-switch {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 30px;
  padding: 10px;
}

.toggle-switch button {
  padding: 10px 20px;
  border: none;
  background-color: #f1f1f1;
  color: #00a3bb;
    cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.toggle-switch button.active {
  background-color: #00a3bb;
  color: #fff;
}

.toggle-switch button:hover {
  background-color: #004d40;
  color: #fff;
}