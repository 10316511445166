/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    h1 {
      font-size: 32px;
    }
  
    .header .nav {
      display: flex;
      background-color: white;
      align-items: center;
      align-content: space-between;
      flex-direction: row;
      position: fixed;
      width: -webkit-fill-available;
      padding: 10px;
      z-index: 1000;
      justify-content: space-between;
      top: 0px;
      left: 0px;
      border-radius: 0px;
    }
  
    .nav img {
      max-width: 20vh;
    }
  
    .navbar-hamburger {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 30px;
    }
  
    .navbar-hamburger .line {
      height: 3px;
      background-color: #00a3bb;
      width: 100%;
    }
  
    .side-panel {
      position: fixed;
      top: 0;
      right: -250px; /* Initially hidden */
      height: 100%;
      width: 250px;
      background-color: #f8f9fa; /* Adjust color */
      box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
      transition: right 0.3s ease;
    }
  
    .side-panel.open {
      right: 0; /* Slide in */
    }
  
    .nav-links {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    .hero {
      margin: 40px 0 0 0;
      display: flex;
      flex-direction: column;
    }
  
    .hero-text {
      text-align: center;
      align-items: center;
      max-width: 100%;
    }
  
    .hero h1 {
      font-size: 20px;
    }
  
    .hero p {
      font-size: 20px;
    }
  
    .hero-image {
      width: 270px;
      padding: 20px 0 0 0;
    }
  
    .hero-buttons button {
      border-radius: 20px;
      border: none;
      padding: 10px 20px;
      font-family: "montserrat";
      font-size: 9px;
    }
  
    .features {
      padding: 12px;
    }
  
    .features h1 {
      font-size: 1.5em;
    }
  
    .feature-card {
      padding: 15px;
      text-align: center;
    }
  
    .specialty-list button {
      padding: 15px 30px;
      font-size: 16px;
    }
  
    .for-practitioners-image img,
    .for-individuals-image img {
      width: 250px;
    }
  
    .practitioners,
    .individuals {
      flex-direction: column; /* Stack practitioners and individuals */
      align-items: center;
      margin: 10px;
    }
  
    .testimonial-cards {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .testimonial-card {
      width: 30vh;
      margin: 0 15px;
    }
  
    .faq {
      margin: 20px;
      padding: 0px;
    }
  
    .contact-section {
      flex-direction: column;
    }

    .video-container img {
      width: inherit;
    }
  
    .download {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .download-img-area img {
      width: 100%;
    }
  
    .download-links {
      -webkit-transform-origin-x: center;
    }
  
    .contact {
      padding: 20px;
      margin: 0px;
    }
  
    .input-wrapper {
      max-width: 100%; /* Full width on smaller screens */
    }
  
    .submit-button {
      width: 100%; /* Full width button */
    }

    .embed-responsive-item{
      max-width: 95%;
      max-height: fit-content;
    }
  }

  @media (max-width: 1024px) {
    .banner-flex{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }