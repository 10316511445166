.doctor-profile-page {
  background-color: #fff;
  padding: 0;
  margin: 0;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='48' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFF' stroke='%2300A1AA' stroke-width='1.25' d='M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z'/%3E%3C/svg%3E"),
    auto;
}

.Nav-logo img {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='48' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFF' stroke='%2300A1AA' stroke-width='1.25' d='M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z'/%3E%3C/svg%3E"),
    auto !important;
}

.nav {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  flex-wrap: wrap;
}

.nav button {
  border: none;
  padding: 10px 20px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  border-radius: 20px;
  transition: 0.3s;
  margin: 5px;
  background-color: white;
  font-family: "montserrat";
  font-weight: bold;
}

.nav button:hover {
  background-color: #50cada;
  color: white;
}

.full-header {
  display: flex;
}

.left-section {
  display: flex;
  align-items: center;
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, #00a3bb, #50cada);
  color: white;
  padding: 30px;
  flex-direction: row;
}

.profile-picture img {
  border-radius: 50%;
  margin-right: 30px;
  width: 55vh;
}

.profile-details h1 {
  font-size: 2rem;
}

.invite-section {
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin: 10vh;
  border: 3px solid #f1f1fd;
  color: #333;
}

.invite-section Input {
  padding: 5px;
  margin-bottom: 5px;
  width: -webkit-fill-available;
  border: 1px solid #50cada;
  border-radius: 5px;
}

.invite-section button {
  background-color: #50cada;
  padding: 10px;
  color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  transition: 0.3s;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='48' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFF' stroke='%2300A1AA' stroke-width='1.25' d='M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z'/%3E%3C/svg%3E"),
    auto;
}

.invite-section button:hover {
  background-color: #00a3bb;
}

.banner-btns {
  display: flex;
  flex-wrap: wrap;
}

.download-cv {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #50cada;
  margin: 5px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  transition: 0.3s;
  width: 25vh;
}

.download-cv:hover {
  background-color: #50cada;
  color: white;
  border: white solid 1px;
  font-weight: bold;
}

.booking-btn {
  padding: 10px 20px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #50cada;
  margin: 5px;
  cursor: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"><path fill="%23FFF" stroke="%2300A1AA" stroke-width="1.25" stroke-linejoin="round" d="M10 11V8.99c0-.88.59-1.64 1.44-1.86h.05A1.99 1.99 0 0 1 14 9.05V12v-2c0-.88.6-1.65 1.46-1.87h.05A1.98 1.98 0 0 1 18 10.06V13v-1.94a2 2 0 0 1 1.51-1.94h0A2 2 0 0 1 22 11.06V14c0 .6-.08 1.27-.21 1.97a7.96 7.96 0 0 1-7.55 6.48 54.98 54.98 0 0 1-4.48 0 7.96 7.96 0 0 1-7.55-6.48C2.08 15.27 2 14.59 2 14v-1.49c0-1.11.9-2.01 2.01-2.01h0a2 2 0 0 1 2.01 2.03l-.01.97v-10c0-1.1.9-2 2-2h0a2 2 0 0 1 2 2V11Z"></path></svg>'),
    auto !important;
  transition: 0.3s;
  width: 51vh;
}

.booking-btn:hover {
  background-color: #50cada;
  color: white;
  border: white solid 1px;
  font-weight: bold;
}

.companies-section {
  text-align: left;
  margin: 40px;
  /* width: 105vh; */
  border-radius: 35px;
  border: #50cada 2px solid;
  padding: 20px;
}

.companies img {
  margin: 0 15px;
  width: 100px;
}

.right-header {
  margin-left: auto;
  width: 300px;
}

.right-header h3 {
  color: white;
}

#calendar-container {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px;
  width: 55vh;
  height: auto;
  overflow: hidden;
  border: solid #50cada 2px;
}

#calendar-container h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  text-align: center;
}

.rbc-calendar {
  background-color: white;
}

.rbc-event {
  background-color: #4caf50; /* Green for available slots */
  color: white;
  border: none;
  text-align: center;
}

.calendar-img {
  text-align: center;
}

.rbc-toolbar .rbc-btn-group:first-child button:first-child {
  display: none;
}

.rbc-toolbar .rbc-btn-group:last-child {
  display: none;
}

.exp-section {
  background: linear-gradient(90deg, #50cada, #00a3bb);
  padding: 15px 15px 15px 5vh;
}

.exp-section-div {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
}

.clinics-available {
  text-align: center;
  padding: 5vh;
  justify-items: center;
}

.clinics-div {
  display: flex;
  justify-content: flex-start;
  gap: 18px;
}

.clinic-small-card {
  background: linear-gradient(45deg, #00a3bb, #50cada);
  color: white;
  border-radius: 10px;
  width: 35vh;
  padding: 25px;
  border: 2px solid white;
  transition: 0.3s;
}

.clinic-small-card:hover {
  top: 10px;
}

.education-here {
  text-align: center;
  left: 15vh;
}

.education-section {
  background: linear-gradient(45deg, #00a3bb, #50cada);
  color: white;
  padding: 5vh;
  text-align: center;
  justify-items: center;
}

.education-card {
  background: white;
  color: #00a3bb;
  border-radius: 10px;
  width: 35vh;
  padding: 25px;
  border: 2px solid #00a3bb;
  transition: 0.3s;
}
